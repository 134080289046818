import React, { FC } from "react";
import { Center } from "./center";
import { Link } from "gatsby";
import LogoLight from "../assets/svg/logo-light.inline.svg";

export const Footer: FC = () => {
  return (
    <footer className="bg-dodger-blue text-white font-serif">
      <Center>
        <div className="py-16 lg:py24 grid grid-cols-1 lg:grid-cols-4">
          <div className="lg:col-span-2">
            <LogoLight style={{ maxWidth: "18rem" }} />

            <p className="mt-4 mb-4">
              &copy; 2009-2019 - Mira VvE-Beheer
              <br />
              Mira VvE B.V. is ingeschreven bij de KvK
              <br />
              Noord Nederland onder nummer 01134017
            </p>

            <div>
              Website door <a href="https://webba.nl">Webba</a>
            </div>
          </div>

          <div className="my-16 lg:my-0">
            <h2 className="text-2xl mb-4">Dienstverlening</h2>

            <p>
              <Link className="block" to="/administratief">
                Administratieve ondersteuning
              </Link>

              <Link className="block" to="/algemeen">
                Algemene ondersteuning
              </Link>

              <Link className="block" to="/technisch">
                Technische ondersteuning
              </Link>

              <Link className="block" to="/bestuur">
                Ondersteuning bestuur
              </Link>
            </p>
          </div>

          <div>
            <h2 className="text-2xl mb-4">Klantenservice</h2>

            <p className="mb-4">
              Mira VvE-Beheer is bereikbaar van maandag tot en met vrijdag
              tussen 9.00 uur en 17.00 uur.
            </p>

            <p>
              Tel: <a href="tel:+31591679001">0591 - 679001</a>
            </p>

            <p>
              Email: <a href="mailto:info@miravve.nl">info@miravve.nl</a>
            </p>
          </div>
        </div>
      </Center>
    </footer>
  );
};
