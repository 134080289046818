import React, { FC, useState } from "react";
import { Link } from "gatsby";
import LogoDark from "../assets/svg/logo-dark.inline.svg";
import IconPhone from "../assets/svg/icon-phone.inline.svg";
import IconArrowLeft from "../assets/svg/icon-arrow-left.inline.svg";
import Hamburger from "hamburger-react";
import { MenuFragment } from "../../graphql-types";

import { motion, AnimatePresence } from "framer-motion";

type Props = {
  menu: MenuFragment;
  currentUrl: string;
};

export const HeaderNarrow: FC<Props> = props => {
  const menuItems = props.menu.menuItems?.nodes;
  const { currentUrl } = props;

  const [stack, setStack] = useState<string[]>([]);

  return (
    <header className="sticky top-0 z-10 lg:hidden">
      <div
        className={`bg-white shadow-md flex justify-between h-16 z-10 lg:hidden transition duration-200 ${
          stack.length ? "bg-dodger-blue" : "bg-white"
        }`}
      >
        <div className="h-full">
          <AnimatePresence>
            {stack.length == 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="h-full"
              >
                <Link
                  className="h-full inline-block p-2 transition duration-200"
                  to="/"
                >
                  <LogoDark style={{ maxWidth: "100%", height: "100%" }} />
                </Link>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {stack.length > 1 && (
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center justify-center bg-dodger blue w-16 h-full outline-none item-center justify-center"
                onClick={() => setStack(stack.slice(0, stack.length - 1))}
              >
                <IconArrowLeft />
              </motion.button>
            )}
          </AnimatePresence>
        </div>

        <div className="flex bg-dodger-blue">
          <AnimatePresence>
            {stack.length === 0 && (
              <motion.a
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                href="tel:+31591679001"
                className="flex flex-shrink-0 items-center justify-center bg-catskill-white w-16"
              >
                <IconPhone />
              </motion.a>
            )}
          </AnimatePresence>

          <div className="flex items-center justify-center w-16 outline-none self-right">
            <Hamburger
              color="#fff"
              toggled={stack.length > 0}
              toggle={() => {
                setStack(stack.length ? [] : ["root"]);
              }}
            />
          </div>
        </div>
      </div>

      <nav
        className={`fixed flex flex-col bg-dodger-blue transition duration-200 w-full ${
          stack.length > 0
            ? "pointer-events-auto opacity-100"
            : "pointer-events-none opacity-0"
        }`}
        style={{ height: "calc(100vh - 4rem)" }}
      >
        <div className="flex relative font-serif text-white flex-grow">
          <div
            className={`absolute top-0 bottom-0 left-0 right-0 overflow-y-auto transition duration-200 ${
              stack.length === 1
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            }`}
          >
            <ul className={`flex flex-col items-start text-2xl pt-1`}>
              {menuItems?.map(menuItem => {
                return (
                  <li
                    className="main-menu-item relative"
                    key={menuItem?.url || ""}
                  >
                    {menuItem?.childItems?.nodes &&
                    menuItem.childItems.nodes.length > 0 ? (
                      <button
                        className="no-underline px-10 py-3 block"
                        dangerouslySetInnerHTML={{
                          __html: menuItem?.label || "",
                        }}
                        onClick={() => {
                          setStack(stack.concat([menuItem.id]));
                        }}
                      />
                    ) : (
                      <Link
                        className="no-underline px-10 py-3 block"
                        to={(menuItem?.url || "").replace(currentUrl || "", "")}
                        activeClassName="active"
                        dangerouslySetInnerHTML={{
                          __html: menuItem?.label || "",
                        }}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          {menuItems?.map(menuItem => {
            return menuItem?.childItems?.nodes &&
              menuItem.childItems.nodes.length > 0 ? (
              <div
                className={`absolute top-0 bottom-0 left-0 right-0 overflow-y-auto transition duration-200 ${
                  stack.includes(menuItem.id)
                    ? "opacity-100 pointer-events-auto"
                    : "opacity-0 pointer-events-none"
                }`}
              >
                <ul
                  key={menuItem?.url || ""}
                  className={`flex flex-col items-start pt-1`}
                >
                  {menuItem?.url && (
                    <li
                      className="main-menu-item relative"
                      key={menuItem.url || ""}
                    >
                      <Link
                        className="no-underline px-10 py-3 block text-2xl"
                        to={(menuItem.url || "").replace(currentUrl || "", "")}
                        activeClassName="active"
                        dangerouslySetInnerHTML={{
                          __html: menuItem.label || "",
                        }}
                      />
                    </li>
                  )}

                  {menuItem.childItems.nodes.map(subMenuItem => {
                    return (
                      <li
                        className="main-menu-item relative text-lg"
                        key={subMenuItem?.url || ""}
                      >
                        <Link
                          className="no-underline px-10 py-1 block"
                          to={(subMenuItem?.url || "").replace(
                            currentUrl || "",
                            ""
                          )}
                          activeClassName="active"
                          dangerouslySetInnerHTML={{
                            __html: subMenuItem?.label || "",
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            );
          })}
        </div>

        <div className="flex justify-center p-8">
          <a
            href="tel:+31591679001"
            className="border border-white text-white inline-block text-center p-3 no-underline"
            style={{ minWidth: "16.25rem" }}
          >
            Bel : 0591 - 679001
          </a>
        </div>
      </nav>
    </header>
  );
};
