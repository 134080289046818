import React, { FC } from "react";
import { HeaderWide } from "./header-wide";
import { HeaderNarrow } from "./header-narrow";
import { Footer } from "./footer";
import { useStaticQuery, graphql } from "gatsby";
import { LayoutQuery, MenuFragment } from "../../graphql-types";
import { Services } from "./services";

export const query = graphql`
  fragment Menu on Wp_Menu {
    menuItems(first: 9999) {
      nodes {
        id
        label
        url
        childItems(first: 9999) {
          nodes {
            id
            label
            url
          }
        }
      }
    }
  }
`;

type Props = {
  showServicesBlock: boolean;
};

export const Layout: FC<Props> = props => {
  const query = useStaticQuery<LayoutQuery>(graphql`
    query Layout {
      wp {
        generalSettings {
          url
        }
        menus(where: { slug: "hoofdmenu" }) {
          nodes {
            ...Menu
          }
        }
      }
    }
  `);

  return (
    <>
      <HeaderNarrow
        menu={
          ((query.wp.menus?.nodes?.length && query.wp.menus.nodes[0]) ||
            []) as MenuFragment
        }
        currentUrl={query.wp.generalSettings?.url || ""}
      />
      <HeaderWide
        menu={
          ((query.wp.menus?.nodes?.length && query.wp.menus.nodes[0]) ||
            []) as MenuFragment
        }
        currentUrl={query.wp.generalSettings?.url || ""}
      />

      <main>
        {props.children}

        {props.showServicesBlock && <Services />}
      </main>

      <Footer />
    </>
  );
};
