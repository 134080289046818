import React, { FC } from "react";
import { Center } from "./center";
import LogoDark from "../assets/svg/logo-dark.inline.svg";
import { Link } from "gatsby";
import { MenuFragment } from "../../graphql-types";

type Props = {
  menu: MenuFragment;
  currentUrl: string;
};

export const HeaderWide: FC<Props> = props => {
  const menuItems = props.menu.menuItems?.nodes;
  const { currentUrl } = props;

  return (
    <header className="bg-white shadow-md sticky top-0 hidden lg:block z-10">
      <Center>
        <div className="flex justify-between items-center h-24">
          <Link className="h-16" to="/">
            <LogoDark style={{ maxWidth: "100%", height: "100%" }} />
          </Link>

          <nav>
            <ul className="flex items-center font-bold">
              {menuItems?.map((menuItem, index) => {
                return (
                  <li
                    className="main-menu-item relative"
                    key={menuItem?.url || ""}
                  >
                    {menuItem?.url ? (
                      <Link
                        className={`no-underline ${index > 0 ? "pl-4" : ""} ${
                          index < menuItems.length - 1 ? "pr-4" : ""
                        } py-5`}
                        to={(menuItem.url || "").replace(currentUrl || "", "")}
                        activeClassName="active"
                      >
                        <span
                          className="border-b-2 border-transparent p-1 transition duration-200"
                          dangerouslySetInnerHTML={{
                            __html: menuItem.label || "",
                          }}
                        ></span>
                      </Link>
                    ) : (
                      <span
                        className={`no-underline ${index > 0 ? "pl-4" : ""} ${
                          index < menuItems.length - 1 ? "pr-4" : ""
                        } py-5`}
                      >
                        <span
                          className="border-b-2 border-transparent p-1 transition duration-200"
                          dangerouslySetInnerHTML={{
                            __html: menuItem?.label || "",
                          }}
                        ></span>
                      </span>
                    )}

                    {menuItem?.childItems?.nodes &&
                      menuItem.childItems.nodes.length > 0 && (
                        <div className="absolute top-0 left-0 transform translate-x-5 translate-y-10 pointer-events-none opacity-0 transition transition-opacity duration-200 main-menu-sub">
                          <ul className="bg-dodger-blue text-white whitespace-no-wrap py-4">
                            {menuItem.childItems.nodes.map(subMenuItem => {
                              return (
                                <li key={subMenuItem?.url || ""}>
                                  <Link
                                    className="main-menu-sub-item no-underline px-5 py-1 inline-block w-full hover:bg-white hover:bg-opacity-25 transition duration-200"
                                    to={(subMenuItem?.url || "").replace(
                                      currentUrl || "",
                                      ""
                                    )}
                                    activeClassName="active"
                                    dangerouslySetInnerHTML={{
                                      __html: subMenuItem?.label || "",
                                    }}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </Center>
    </header>
  );
};
