import React, { FC } from "react";

export const Center: FC = props => {
  return (
    <div
      className="mx-auto px-5 lg:px-20 box-content center-component"
      style={{ maxWidth: "75rem" }}
    >
      {props.children}
    </div>
  );
};
