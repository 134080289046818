import React, { FC } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ServicesQuery } from "../../graphql-types";
import { Center } from "./center";
import IconService from "../assets/svg/icon-service.inline.svg";
import { motion } from "framer-motion";

export const Services: FC = () => {
  const query = useStaticQuery<ServicesQuery>(graphql`
    query Services {
      wp {
        globaal {
          globalCustomFields {
            services {
              description
              name
              link {
                url
              }
            }
          }
        }
      }
    }
  `);

  const services = query.wp.globaal?.globalCustomFields?.services;

  return (
    <>
      <div className="background-stripes">
        <Center>
          <div className="text-dodger-blue font-serif text-xl md:text-2xl pb-16 pt-10 lg:pt-20">
            Mira VvE-Beheer kan uw vereniging op verschillende gebieden van
            dienst zijn:
          </div>
        </Center>
      </div>

      <div className="services-block bg-catskill-white">
        <Center>
          <ul className="-mx-5 md:mx-0 services-list flex h-56 transform -translate-y-8 overflow-x-auto overflow-y-hidden md:overflow-visible">
            {services?.map((service, index) => {
              return (
                <motion.li
                  key={index}
                  initial="idle"
                  whileHover="active"
                  variants={{
                    idle: {
                      y: 0,
                    },
                    active: {
                      y: "-1rem",
                      transition: {
                        delayChildren: 0.2,
                      },
                    },
                  }}
                  className={`flex-grow flex-shrink-0 md:w-1/4 ${
                    index === 0 ? "pl-5" : ""
                  } pr-5 md:px-0`}
                >
                  <Link
                    className={`relative p-8 md:p-10 inline-block w-64 md:w-full flex flex-col items-center justify-center bg-white border md:border-r-0 min-h-full no-underline`}
                    style={{
                      borderColor: "rgba(0,0,0,0.1)",
                    }}
                    to={service?.link?.url || ""}
                  >
                    <motion.div
                      variants={{
                        idle: { opacity: 0 },
                        active: { opacity: 1 },
                      }}
                      style={{ height: 2 }}
                      className="bg-dodger-blue w-full absolute top-0 left-0"
                    />

                    <div
                      className={`w-18 h-18 mb-8 flex items-center justify-center ${
                        [
                          "bg-malibu-dark",
                          "bg-navy-blue",
                          "bg-malibu-light",
                          "bg-dodger-blue",
                        ][index % 4]
                      }`}
                    >
                      <IconService />
                    </div>

                    <div
                      className="text-dodger-blue font-serif text-lg"
                      dangerouslySetInnerHTML={{ __html: service?.name || "" }}
                    />

                    <motion.div
                      variants={{
                        idle: { height: 0, opacity: 0, y: 30 },
                        active: { height: "auto", opacity: 1, y: 0 },
                      }}
                      className="mt-3 text-center text-black"
                      transition={{
                        type: "spring",
                        damping: 100,
                        stiffness: 1500,
                        mass: 3,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: service?.description || "",
                      }}
                    />
                  </Link>
                </motion.li>
              );
            })}
          </ul>
        </Center>
      </div>
    </>
  );
};
